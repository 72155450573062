import React, { lazy,Suspense } from "react";
import { Spin } from 'antd';
import { Routes, Route} from "react-router-dom";
import './index.scss'
const Home = lazy(() => import('../pages/Home'));
const NotFound = lazy(() => import('../pages/error/NotFound'))
const Detail = lazy(() => import('../pages/Detail'))
const List = lazy(() => import('../pages/List'))

const Piclist = lazy(() => import('../pages/Pic'))
const Xlist = lazy(() => import('../pages/Xlist'))


const Mr = lazy(() => import('../pages/Mr'))

const Rs = () => {
  return (
    <>
      <Suspense fallback={
        <div className="wrap">
          <div className="center"><Spin size="large" tip="Loading..." /></div>
        </div>
      }>
        <Routes> 
        <Route path="/" element={<Home />} >
           <Route index="true" element={<Mr />} /> 
          <Route path="/cate/:id" element={<List />} />          
          <Route path="/piclist/:id" element={<Piclist />} />
          <Route path="/cates/:id" element={<Xlist />} />
          <Route path="/detail/:id" element={<Detail />} />
          
        </Route>  
             
          <Route path="*" element={<NotFound />} />
        </Routes>

      </Suspense>
    </>

  )
}

export default Rs