import './App.css';
import Rs from './routers';
//交由mobx进行状态管理
import {observer} from "mobx-react-lite"

function App() {
  return (
    <Rs />
  );
}

export default observer(App);
