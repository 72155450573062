import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {HashRouter as HistoryRouter} from "react-router-dom"
import reportWebVitals from './reportWebVitals';
import {history} from './utils'
//import 'default-passive-events'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>

    <HistoryRouter history={history}>       
    <App /> 
    </HistoryRouter>
 // </React.StrictMode>
);
reportWebVitals();
