import { createFromIconfontCN } from '@ant-design/icons';
import { http } from './http'
import {
  setToken,
  getToken,
  removeToken
} from './token'

import { history } from './history'
import { Icon } from './icon'
import {objToArr,objToArrkey,splictArr} from './objToArr'
import {Validate} from './Validate'
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3543165_633o2g2y8gj.js',
});

//const baseURL= 'http://localhost:8081/api';



export {
  http,
  setToken,
  getToken,
  removeToken,
  history,
  Icon,
  objToArr,
  Validate,
  IconFont,
  objToArrkey,
  splictArr,
  //baseURL
}
